const api = {
  config: "/config.json",
  camerasListById: (cameraId: number | null, uuid: string | null): string => `/capture/camera/${cameraId}/${uuid}`,
  cameraStatus: (experienceName: string, cameraId: number, uuid: string): string =>
    `/capture/${experienceName}/status/${cameraId}/${uuid}`,
  makeCapture: (experienceName: string): string => `/capture/${experienceName}/capture`,
  getCaptureAvailability: (experienceName: string): string => `/capture/${experienceName}/capture-availability`,
};

export default api;
